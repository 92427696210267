@import 'libs/styles/src/common/common';

@layer shared-component {
  .progress {
    pointer-events: none;

    .bar {
      background: $blue;
      position: fixed;
      z-index: 1031;
      top: 0;
      left: 0;
      height: 2px;
      animation: bar 0.5s ease-in-out 1;
    }
  }

  @keyframes bar {
    0% {
      width: 0;
    }

    100% {
      width: 100%;
    }
  }

  // element.style {
  //   transform: translate3d(-0.6%, 0, 0);
  //   transition: all 200ms ease 0s;
  // }
}
