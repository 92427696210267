@import 'libs/styles/src/common/common';

@layer shared-component {
  .skeleton {
    background-color: #eee;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    margin-bottom: -1px; // fix line height issue
    width: 100%;
    position: relative;
    overflow: hidden;
    vertical-align: top; // Very important fixes overflow hidden bug

    &.with-animation {
      &::after {
        content: '';
        background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
        animation: animation-skeleton 1.2s ease-in-out infinite;
        width: 200px;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
      }
    }

    .children {
      visibility: hidden;
    }
  }

  @keyframes animation-skeleton {
    0% {
      transform: translateX(-200px);
    }

    100% {
      transform: translateX(calc(400px + 100%));
    }
  }
}
