@import 'libs/styles/src/common/common';

@layer component {
  .header-menu {
    transition: transform 0.4s ease-in-out;
    position: absolute;
    transform: translate(0, -125%); // Extra 25% for box-shadow
    top: 0;
    left: 0;
    right: 0;

    &.active {
      transform: translate(0, 0%);
    }
  }
}
