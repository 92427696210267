@import 'libs/styles/src/common/common';

@layer component {
  .notification-container {
    position: fixed;
    top: 24px;
    left: 0;
    width: 100%;
    z-index: 1051;
    height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .notification-tooltip {
    display: flex;
    border-radius: 8px;
    padding: 0.25rem 1.5rem;
    gap: 1rem;
    box-shadow: $small-shadow;
    text-align: center;
    transition: opacity 0.25s cubic-bezier(0.75, 0, 0.25, 1);
    opacity: 0;
    visibility: hidden;
    z-index: 1050;
    margin: 0 1rem;
    align-items: center;
    margin-bottom: 1rem;
    overflow-wrap: anywhere;

    &--active {
      opacity: 1;
      visibility: unset;
    }

    &.confirm {
      background: $confirm;
    }

    &.info {
      background: $blue;
    }

    &.warn {
      background: $warn;
    }

    &.error {
      background: $red;
    }
  }

  .text {
    font-size: 1rem;
    font-weight: 600;
    color: $highlight-light-blue;
  }

  .close {
    margin-right: -1rem;
  }
}
