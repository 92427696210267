@import 'common';

@layer base {
  // Copied from Bootstrap Grid
  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  @import 'bootstrap/scss/functions';
  @import 'bootstrap/scss/variables';
  @import 'bootstrap/scss/mixins/deprecate';
  @import 'bootstrap/scss/mixins/breakpoints';
  @import 'bootstrap/scss/mixins/grid-framework';
  @import 'bootstrap/scss/mixins/grid';
  @import 'bootstrap/scss/grid';
  @import '~bootstrap/scss/utilities/screenreaders';
  @import '~bootstrap/scss/alert';
  @import '~bootstrap/scss/progress';

  body {
    --font-family-sans-serif: #{$font-family-sans-serif};
    --font-family-serif: #{$font-family-serif};

    background: $light-grey;
    font-family: var(--font-family-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    line-height: 1.2;
  }

  ol,
  ul {
    line-height: 1.6;
  }

  #mf-root {
    position: relative;
  }

  .hide-h-tag {
    text-indent: -99999px;
    max-height: 0;
    margin: 0;
    display: block;
  }

  a,
  .mf-link {
    color: $blue;
    text-decoration: none;
    background-color: transparent;

    &:hover {
      color: $blue-hover;
      text-decoration: underline;
    }
  }

  img,
  svg {
    vertical-align: middle;
  }

  div:not([role='button']) {
    outline: none !important;
  }

  button {
    cursor: pointer;
    font-family: var(--font-family-sans-serif);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 0.5rem;
    font-weight: 700;
  }

  h1 {
    font-size: 2.5rem;
    color: $dark-blue-text;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

  @media (max-width: 550px) and (min-width: 400px) {
    h1 {
      font-size: 1.75rem;
    }

    h2 {
      font-size: 1.5rem;
    }

    h3 {
      font-size: 1.25rem;
    }

    h4 {
      font-size: 1.15rem;
    }

    h5 {
      font-size: 1rem;
    }

    h6 {
      font-size: 0.85rem;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    h3 {
      font-size: 1.15rem;
    }

    h4 {
      font-size: 1rem;
    }

    h5 {
      font-size: 0.85rem;
    }

    h6 {
      font-size: 0.75rem;
    }
  }
}

@layer override {
  .MF_ReactModal__Body--open {
    overflow: hidden;
  }

  .MF_Modal__Body--open {
    overflow: hidden;
  }

  .MF_Modal__Body--open {
    overflow: hidden;
  }

  .MF_Modal__Body--open--scrollable {
    overflow: hidden;
  }

  .hide {
    &--up {
      &--xxl {
        // complement of `hide--xl`
        @include media-breakpoint-up(xxl) {
          display: none !important;
        }
      }

      &--xl {
        // complement of `hide--lg`
        @include media-breakpoint-up(xl) {
          display: none !important;
        }
      }

      &--lg {
        // complement of `hide--md`
        @include media-breakpoint-up(lg) {
          display: none !important;
        }
      }

      &--md {
        // complement of 'hide--sm'
        @include media-breakpoint-up(md) {
          display: none !important;
        }
      }

      &--sm {
        // complement of 'hide--xs'
        @include media-breakpoint-up(sm) {
          display: none !important;
        }
      }
    }

    &--xxl {
      @include media-breakpoint-down(xxl) {
        display: none !important;
      }
    }

    &--xl {
      @include media-breakpoint-down(xl) {
        display: none !important;
      }
    }

    &--lg {
      @include media-breakpoint-down(lg) {
        display: none !important;
      }
    }

    &--md {
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }

    &--sm {
      @include media-breakpoint-down(sm) {
        display: none !important;
      }
    }

    &--xs {
      @include media-breakpoint-down(xs) {
        display: none !important;
      }
    }

    &--always {
      display: none !important;
    }
  }
}
